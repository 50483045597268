import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { OPERATOR_ROLES } from "../../constants";

import {
  TextField,
  Grid,
  MenuItem,
  Collapse,
  Autocomplete,
  IconButton,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import DefaultDialog from "../Dialogs/DefaultDialog";
import { Section } from "../Dialogs/controls";

const CreateEditOperatorDialog = ({
  open,
  setOpen,
  operator,
  handleSubmit,
  vendorOptions = [],
  locationOptions = [],
}) => {
  const theme = useTheme();
  const textfieldStyles = { height: "1.2rem" };

  const isCreate = _.isNil(operator);

  const [showPin, setShowPin] = useState(false);

  const schema = Yup.object().shape({
    name: Yup.object({
      first: Yup.string().required("First name is required"),
      last: Yup.string().required("Last name is required"),
    }),
    pin: Yup.string()
      .matches(/^\d+$/, "PIN can only use numbers")
      .length(6, "PIN must be 6 numbers")
      .required("PIN is required"),
    role: Yup.string().required("Role is required"),
    vendor: Yup.string().required("Vendor is required"),
    locations: Yup.array().min(1, "At least one location is required"),
  });

  const getInitialValues = (operator) => {
    let initVals = {
      name: {
        first: "",
        last: "",
      },
      pin: "",
      role: "",
      vendor: "",
      locations: [],
    };

    if (!_.isNil(operator)) {
      initVals = _.mergeWith({}, initVals, operator, (objVal, srcVal, key) => {
        if (_.isObject(srcVal)) {
          if (key === "locations") {
            return srcVal;
          } else {
            return _.get(srcVal, "_id");
          }
        }
      });
    }

    if (vendorOptions.length === 1)
      initVals.vendor = _.get(vendorOptions, ["0", "_id"]);

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(operator),
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        const operatorToSubmit = _.cloneDeep(values);
        const { locations } = operatorToSubmit;

        _.set(
          operatorToSubmit,
          "locations",
          locations.map((loc) => loc._id)
        );

        handleSubmit(operatorToSubmit);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  const vendorDict = useMemo(() => {
    return vendorOptions.reduce((dict, vendor) => {
      const { _id } = vendor;
      if (!_.has(dict, _id)) {
        dict[_id] = {
          locations: _.get(vendor, "allowedLocations", []).map((loc) =>
            _.get(loc, "_id", loc)
          ),
        };
      }

      if (_.get(vendor, "allowAllLocations", false)) {
        dict[_id].locations = locationOptions.map((loc) =>
          _.get(loc, "_id", loc)
        );
      }

      return dict;
    }, {});
  }, [vendorOptions, locationOptions]);

  // filter locations by selected vendor
  const filteredLocations = useMemo(() => {
    const selectedVendor = values.vendor;
    if (_.isEmpty(selectedVendor)) {
      return locationOptions;
    }

    const vendorLocations = _.get(
      vendorDict,
      [selectedVendor, "locations"],
      []
    );

    return locationOptions.filter(({ _id }) => vendorLocations.includes(_id));
  }, [values.vendor, locationOptions, vendorDict]);

  useEffect(() => {
    if (!open) {
      resetForm();
      setShowPin(false);
    }
  }, [open]);

  /* Clears the location field when it finds that the selected location is not one of the allowed locations
  for the selected vendor */
  useEffect(() => {
    const selectedLocations = values.locations;

    if (!_.isEmpty(selectedLocations)) {
      const sharedLocations = _.intersectionBy(
        selectedLocations,
        filteredLocations,
        "_id"
      );

      if (_.isEmpty(sharedLocations)) {
        setFieldTouched("locations");
        setFieldValue("locations", []);
      }
    }
  }, [filteredLocations, values.locations]);

  return (
    <DefaultDialog
      open={open}
      title={`${isCreate ? "Create" : "Edit"} Operator`}
      iconType={isCreate ? "add" : "edit"}
      handleClose={() => setOpen(false)}
      handleConfirm={handleSubmitFormik}
      confirmButtonName={isCreate ? "Create" : "Update"}
      closeButtonName="Cancel"
      disableConfirm={isSubmitting}
    >
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Section title="Name" theme={theme}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    {...getFieldProps("name.first")}
                    error={Boolean(touched.name?.first && errors.name?.first)}
                    helperText={touched.name?.first && errors.name?.first}
                    label="First"
                    InputLabelProps={{
                      style: textfieldStyles,
                      shrink: !_.isEmpty(values.name?.first),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    {...getFieldProps("name.last")}
                    error={Boolean(touched.name?.last && errors.name?.last)}
                    helperText={touched.name?.last && errors.name?.last}
                    label="Last"
                    InputLabelProps={{
                      style: textfieldStyles,
                      shrink: !_.isEmpty(values.name?.last),
                    }}
                  />
                </Grid>
              </Grid>
            </Section>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              type={showPin ? "text" : "password"}
              {...getFieldProps("pin")}
              error={Boolean(touched.pin && errors.pin)}
              helperText={touched.pin && errors.pin}
              label="PIN"
              InputLabelProps={{
                style: textfieldStyles,
                shrink: !_.isEmpty(values.pin),
              }}
              inputProps={{ inputMode: "numeric" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPin((prev) => !prev);
                      }}
                    >
                      {showPin ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              select
              fullWidth
              {...getFieldProps("role")}
              error={Boolean(touched.role && errors.role)}
              helperText={touched.role && errors.role}
              label="Role"
              InputLabelProps={{
                style: textfieldStyles,
                shrink: !_.isEmpty(values.role),
              }}
            >
              {OPERATOR_ROLES.map((role) => (
                <MenuItem value={role} key={role}>
                  {role}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              select
              fullWidth
              {...getFieldProps("vendor")}
              error={Boolean(touched.vendor && errors.vendor)}
              helperText={touched.vendor && errors.vendor}
              label="Vendor"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.vendor),
              }}
              onChange={(e) => {
                const vendor = e.target.value;
                setFieldValue("vendor", vendor);
                setFieldValue("locations", []);
              }}
            >
              {_.isEmpty(vendorOptions) ? (
                <MenuItem disabled={true}>No vendors</MenuItem>
              ) : (
                vendorOptions.map((vendor) => (
                  <MenuItem value={vendor._id} key={vendor._id}>
                    {_.get(vendor, "name", "Unknown")}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              padding: _.isEmpty(values.vendor) ? "0 !important" : "inherit",
            }}
          >
            <Collapse in={!_.isEmpty(values.vendor)}>
              <Autocomplete
                multiple
                filterSelectedOptions
                disableCloseOnSelect
                value={values.locations}
                getOptionLabel={(location) =>
                  `${location.name} (${location.collectorCode})`
                }
                onChange={(event, value) => setFieldValue("locations", value)}
                options={filteredLocations}
                isOptionEqualToValue={(option, value) =>
                  _.get(option, "_id", 1) === _.get(value, "_id", 2)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Locations"
                    error={Boolean(touched.locations && errors.locations)}
                    helperText={touched.locations && errors.locations}
                    InputLabelProps={{
                      style: textfieldStyles,
                    }}
                  />
                )}
              />
            </Collapse>
          </Grid>
        </Grid>
      </FormikProvider>
    </DefaultDialog>
  );
};

export default CreateEditOperatorDialog;
