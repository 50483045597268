import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import moment from "moment-timezone";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useSnackbar from "../../hooks/useSnackbar";

import { getDeviceName } from "../Table/misc";
import { makeData } from "../Table/dataTest";

import {
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  colors,
  Popover,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { fuzzyFilter, arraySort, getSortVal, enumFilter } from "../Table/misc";
import { ActionCell } from "../Table/controls";

import LocalTable from "../Table/LocalTable";
import DeleteDialog from "../Dialogs/DeleteDialog";
import CreateEditVendorDialog from "./CreateEditVendorDialog";

const Vendors = () => {
  const theme = useTheme();
  const { get, post } = useHttp();
  const { loading, setLoading } = useLoading();
  const { openSnackbar } = useSnackbar();

  const [editingVendor, setEditingVendor] = useState();
  const [showEditVendorDialog, setShowEditVendorDialog] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState();
  const [showDeleteVendorDialog, setShowDeleteVendorDialog] = useState(false);

  // const [data, setData] = useState(() => makeData(50, "vendor"));
  const [data, setData] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const [allLocations, setAllLocations] = useState([]);
  const [allDepositGroups, setAllDepositGroups] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(100);
  const [popoverContent, setPopoverContent] = useState();

  const getLocations = async () => {
    setLoading(true);
    const res = await get("/getLocations");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setAllLocations(res.data.locations);
    }
    setLoading(false);
  };

  const getDepositGroups = async () => {
    setLoading(true);
    const res = await get("/getDepositGroups");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setAllDepositGroups(res.data.depositGroups);
    }
    setLoading(false);
  };

  const getVendors = async () => {
    setLoading(true);
    const res = await get("/getVendors");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setData(res.data.vendors);
    }
    setLoading(false);
  };

  const handleOpenPopover = (event) => {
    const target = event.currentTarget;
    setAnchorEl(target);
    setPopoverWidth(target.clientWidth);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCreateVendor = async (newVendor) => {
    setLoading(true);
    const res = await post("/createVendor", { newVendor });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditVendorDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Successfully created vendor"
        : "Failed to create vendor",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleEditVendor = async (newVendor) => {
    setLoading(true);
    const res = await post("/updateVendor", {
      newVendor,
      _id: editingVendor._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditVendorDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Successfully updated vendor details"
        : "Failed to update vendor details",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleDeleteVendor = async () => {
    setLoading(true);
    const res = await post("/deleteVendor", {
      _id: vendorToDelete._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowDeleteVendorDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Successfully deleted vendor"
        : "Failed to delete vendor",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  useEffect(() => {
    getVendors();
  }, [refetch]);

  useEffect(() => {
    getLocations();
    getDepositGroups();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        header: "Actions",
        size: 175,
        enableHiding: false,
        sticky: "left",
        cell: ({ row }) => {
          const vendor = row.original;
          return ActionCell({
            row,
            theme,
            handleEdit: () => {
              setEditingVendor(vendor);
              setShowEditVendorDialog(true);
            },
            handleDelete: () => {
              setVendorToDelete(vendor);
              setShowDeleteVendorDialog(true);
            },
          });
        },
      },
      {
        header: "Name",
        size: 200,
        minSize: 175,
        enableHiding: false,
        accessorKey: "name",
        // filterFn: fuzzyFilter,
        filterFn: "includesString",
      },
      {
        id: "createdAt",
        header: "Active Since",
        size: 175,
        minSize: 150,
        accessorFn: (row) =>
          moment(_.get(row, "createdAt")).format("YYYY-MM-DD HH:mm:ss"),
        sortingFn: (rowA, rowB, colId) => {
          const dateA = new Date(_.get(rowA, "original.createdAt"));
          const dateB = new Date(_.get(rowB, "original.createdAt"));

          return getSortVal(dateA, dateB);
        },
        // filterFn: fuzzyFilter,
        filterFn: "includesString",
      },
      {
        id: "deviceTypes",
        header: "Allowed Device Types",
        size: 220,
        minSize: 220,
        accessorFn: (row) => _.get(row, "deviceTypes", []).join(","),
        cell: ({ row }) => {
          const types = _.get(row, "original.deviceTypes", []).sort();
          return (
            <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
              {types.map((type) => (
                <Chip label={_.upperCase(type)} />
              ))}
            </Stack>
          );
        },
        sortingFn: (rowA, rowB) => arraySort(rowA, rowB, "deviceTypes"),
        filterFn: "includesString",
      },
      {
        id: "apiKeys",
        header: "No. of API Keys",
        size: 175,
        minSize: 175,
        accessorFn: (row) => _.get(row, "apiKeys", []).length,
      },
      {
        id: "users",
        header: "Users",
        size: 225,
        minSize: 200,
        accessorFn: (row) =>
          _.get(row, "users", [])
            .map((user) => _.get(user, "name.full"))
            .join(","),
        cell: ({ row }) => {
          // will probably need the users' ID for links so can't just use accessor values
          const users = _.get(row, "original.users", []);
          const userCount = users.length;

          const popoverUsers = _.sortBy(users, ["name.first", "name.last"]).map(
            (user) => (
              <Typography key={user._id} variant="body2" sx={{ p: 1 }}>
                {_.get(user, "name.full")}
              </Typography>
            )
          );

          return (
            <Button
              onClick={(e) => {
                handleOpenPopover(e);
                setPopoverContent(popoverUsers);
              }}
              className="w-full text-center"
              variant="outlined"
              disabled={!userCount}
            >
              {userCount} user{userCount !== 1 && "s"}
            </Button>
          );
        },
        sortingFn: (rowA, rowB) => arraySort(rowA, rowB, "users"),
        // filterFn: fuzzyFilter,
        filterFn: "includesString",
      },
      {
        id: "devices",
        header: "Devices",
        size: 225,
        minSize: 200,
        accessorFn: (row) =>
          _.get(row, "devices", [])
            .map((device) => getDeviceName(device))
            .join(","),
        cell: ({ row }) => {
          // will probably need the devices' ID for links so can't just use accessor values
          const devices = _.get(row, "original.devices", []);
          const deviceCount = devices.length;

          const popoverDevices = _.sortBy(devices, [
            "type",
            "serialNumber",
          ]).map((device) => (
            <Typography key={device._id} variant="body2" sx={{ p: 1 }}>
              {getDeviceName(device)}
            </Typography>
          ));

          return (
            <Button
              onClick={(e) => {
                handleOpenPopover(e);
                setPopoverContent(popoverDevices);
              }}
              className="w-full text-center"
              variant="outlined"
              disabled={!deviceCount}
            >
              {deviceCount} device{deviceCount !== 1 && "s"}
            </Button>
          );
        },
        sortingFn: (rowA, rowB) => arraySort(rowA, rowB, "devices"),
        // filterFn: fuzzyFilter,
        filterFn: "includesString",
      },
      {
        id: "allowedLocations",
        header: "Locations",
        size: 225,
        minSize: 200,
        accessorFn: (row) => {
          // Not actually sure what this is meant to display...
          // Looks like the cell handler deals with display
          const allowAllLocations = _.get(row, "allowAllLocations", false);

          if (allowAllLocations) {
            return allLocations
              .map((location) => _.get(location, "name"))
              .join(",");
          }

          return _.get(row, "allowedLocations", [])
            .map((location) => _.get(location, "name"))
            .join(",");
        },
        cell: ({ row }) => {
          const allowAllLocations = _.get(
            row,
            "original.allowAllLocations",
            false
          );

          if (allowAllLocations) {
            return (
              <Button
                // onClick={(e) => {
                //   handleOpenPopover(e);
                //   setPopoverContent(popoverLocations);
                // }}
                className="w-full text-center"
                variant="outlined"
                // disabled={true}
              >
                All Locations
              </Button>
            );
          }

          // will probably need the locations' ID for links so can't just use accessor values
          const locations = _.get(row, "original.allowedLocations", []);
          const locationCount = locations.length;

          const popoverLocations = _.sortBy(locations, ["name"]).map(
            (location) => (
              <Typography key={location._id} variant="body2" sx={{ p: 1 }}>
                {`${location.name} (${location.collectorCode})`}
              </Typography>
            )
          );

          return (
            <Button
              onClick={(e) => {
                handleOpenPopover(e);
                setPopoverContent(popoverLocations);
              }}
              className="w-full text-center"
              variant="outlined"
              disabled={!locationCount}
            >
              {locationCount} location{locationCount !== 1 && "s"}
            </Button>
          );
        },
        sortingFn: (rowA, rowB) => arraySort(rowA, rowB, "allowedLocations"),
        // filterFn: fuzzyFilter,
        filterFn: "includesString",
      },
    ];
  }, []);

  const popoverOpen = Boolean(anchorEl);

  return (
    <>
      <Grid
        className="w-full overflow-hidden rounded-lg"
        style={{
          margin: theme.spacing(2),
          padding: theme.spacing(3),
          backgroundColor: colors.grey[100],
        }}
      >
        <Typography variant="h4">Vendors</Typography>
        <Divider
          style={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        />
        <LocalTable name="vendors-table" data={data} columns={columns}>
          <Button
            variant="contained"
            onClick={() => {
              setEditingVendor();
              setShowEditVendorDialog(true);
            }}
            disabled={loading}
            sx={{ width: "max-content" }}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Create Vendor
          </Button>
        </LocalTable>
      </Grid>
      <DeleteDialog
        open={showDeleteVendorDialog}
        handleClose={() => setShowDeleteVendorDialog(false)}
        handleDelete={handleDeleteVendor}
        text={`Are you sure you want to delete vendor, ${_.get(
          vendorToDelete,
          "name",
          ""
        )}?`}
      />
      <CreateEditVendorDialog
        open={showEditVendorDialog}
        vendor={editingVendor}
        depositGroups={allDepositGroups}
        setOpen={setShowEditVendorDialog}
        handleSubmit={editingVendor ? handleEditVendor : handleCreateVendor}
        locationOptions={allLocations}
      />
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack
          style={{
            width: `${popoverWidth}px`,
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          {popoverContent}
        </Stack>
      </Popover>
    </>
  );
};

export default Vendors;
